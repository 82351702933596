@tailwind base;
@tailwind components;
@tailwind utilities;

/* BRAND */
:root {
  --light-50: #fbfbfb;
  --light-100: #f8f8f8;
  --light-200: #e1e1e1;
  --light-300: #e5e5e5;
  --light-400: #e6e6e6;
  --light-DEFAULT: #fcfcfc;
  --light-550: #cdcdcd;
  --light-600: #c4c4c4;
  --light-700: #d6d6d6;
  --primary-DEFAULT: #7870ff;
  --prmary-700: #0078ef;
  --secondary-400: #9b98a3;
  --secondary-DEFAULT: #919191;
  --info-DEFAULT: #483c62;
  --info-600: #383147;
  --info-800: #221a33;
  --warning-DEFAULT: #ddaa00;
  --dark-DEFAULT: #202020;
  --dark-600: #1b1b1b;
  --pink-DEFAULT: #ff7092;
  --danger-DEFAULT: #f9595f;

  --radius-2xs: 3px;
  --radius-xs: 3.5px;
  --radius-2sm: 4px;
  --radius-sm: 4.5px;
  --radius-DEFAULT: 5px;
  --radius-lg: 8px;
  --radius-xl: 10px;
  --radius-2xl: 15px;
  --radius-3xl: 20px;
  --radius-4xl: 24px;
  --radius-5xl: 50px;

  --shadow-dark-sm: 0px 10px 20px rgba(67, 67, 67, 0.05);
  --shadow-dark: 0px 10px 40px rgba(67, 67, 67, 0.05);

  --font-family-weight: Poppins, 300, 400, 600, 700;

  --font-size-1: 9.5px;
  --font-size-1: 10px - 1;
  --font-size-1: 12px - 1;
  --font-size-1: 12px - 2;
  --font-size-1: 14px - 1.1;
  /* body */
  --font-size-lead: 14px - 2, 14px - 2;
  --font-size-1: 16px - 1, 1.5;
  --font-size-1: 17.5px - 1, 1.5;
  --font-size-1: 18px - 1, 1.5;

  --font-size-h1: 60px - 1.2, 1.25, 42px, 48px;
  --font-size-h2: 40px - 1.25, 36px;
  --font-size-h2-2: 38px - 1, 1.25, 34px;
  --font-size-h3: 36px - 1.65, 1.25, 32px;
  --font-size-h3-2: 34px - 1, 1.25, 30px;
  --font-size-h4: 32px - 1, 1.25, 28px;
  --font-size-h5: 28px - 1, 1.35, 24px;
  --font-size-h6: 20px - 1.4;
}

html,
body,
#root {
  height: 100%;
}

.bg-connections-pattern {
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M36 0H0v36h36V0zM15.126 2H2v13.126c.367.094.714.24 1.032.428L15.554 3.032c-.188-.318-.334-.665-.428-1.032zM18 4.874V18H4.874c-.094-.367-.24-.714-.428-1.032L16.968 4.446c.318.188.665.334 1.032.428zM22.874 2h11.712L20 16.586V4.874c1.406-.362 2.512-1.468 2.874-2.874zm10.252 18H20v13.126c.367.094.714.24 1.032.428l12.522-12.522c-.188-.318-.334-.665-.428-1.032zM36 22.874V36H22.874c-.094-.367-.24-.714-.428-1.032l12.522-12.522c.318.188.665.334 1.032.428zm0-7.748V3.414L21.414 18h11.712c.362-1.406 1.468-2.512 2.874-2.874zm-18 18V21.414L3.414 36h11.712c.362-1.406 1.468-2.512 2.874-2.874zM4.874 20h11.712L2 34.586V22.874c1.406-.362 2.512-1.468 2.874-2.874z' fill='%23fefefe' fill-opacity='0.13' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.bg-diagonal-lines-pattern {
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='0.25' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

div[role='button'][tabindex='0'] {
  @apply cursor-default;
}

@layer components {
  body {
    @apply overflow-x-hidden antialiased font-sans text-sm leading-normal font-normal text-black bg-white;
    -webkit-tap-highlight-color: transparent;
  }

  .container {
    @apply mx-auto px-[15px] max-w-full xs:px-[20px] sm:max-w-[540px] md:max-w-[750px] lg:max-w-[980px] xl:max-w-[1150px] 3xl:max-w-[1340px];
  }

  .container-2 {
    /* @apply mx-auto px-[15px] max-w-full xs:px-[20px] sm:max-w-[540px] md:max-w-[750px] lg:max-w-full 2xl:max-w-[1400px] 3xl:max-w-[1440px]; */
    @apply mx-auto px-[15px] max-w-full xs:px-[20px] sm:max-w-[540px] md:max-w-[750px] lg:max-w-full 2xl:max-w-[1340px];
  }

  .container-3 {
    /* @apply mx-auto px-[15px] max-w-full xs:px-[20px] sm:max-w-[540px] md:max-w-[750px] lg:max-w-full lg:px-0 2xl:max-w-[1400px] 2xl:px-[20px] 3xl:max-w-[1440px]; */
    @apply mx-auto px-[15px] max-w-full xs:px-[20px] sm:max-w-[540px] md:max-w-[750px] lg:max-w-full lg:px-0 2xl:max-w-[1340px] 2xl:px-[20px];
  }

  .aos-section {
    @apply overflow-hidden;
  }

  .h1 {
    @apply text-4.25xl leading-tight md:text-5xl md:leading-tight lg:text-6xl lg:leading-tighter;
  }
  .h2 {
    @apply text-4xl leading-tight md:text-4.15xl;
  }
  .h2-2 {
    @apply text-3.5xl leading-tight md:text-4.1xl;
  }
  .h3 {
    @apply text-3.25xl  leading-tight md:text-4xl;
  }
  .h3-2 {
    @apply text-3xl leading-tight md:text-3.5xl;
  }
  .h4 {
    @apply text-2.5xl leading-tight md:text-3.25xl;
  }
  .h5 {
    @apply text-2xl leading-snug md:text-2.5xl;
  }
  .h6 {
    @apply text-xl leading-snuger;
  }
  .lead {
    @apply text-base leading-loose text-secondary;
  }
}

@layer components {
  .btn {
    @apply inline-block py-[13px] px-7 text-base leading-tight font-normal text-center capitalize select-none no-underline hover:no-underline focus:no-underline border border-transparent rounded;
  }

  .btn::before,
  .btn::after {
    @apply rounded;
  }

  .btn-sm {
    @apply py-[12px] px-6 text-sm leading-tight rounded-xs;
  }

  .btn-sm::before,
  .btn-sm::after {
    @apply rounded-xs;
  }

  .btn-lg {
    @apply py-[16px] px-8 text-base leading-tight;
  }

  .btn-circle {
    @apply rounded-full;
  }

  .btn-circle::before,
  .btn-circle::after {
    @apply rounded-full;
  }

  .btn-icon {
    @apply inline-flex justify-center items-center w-[47px] h-[47px] p-0;
  }

  .btn-icon > img,
  .btn-icon > svg,
  .btn-icon > i {
    @apply flex-shrink-0 text-[24px] w-[24px] h-[24px] leading-none;
  }

  .btn-icon > .bs-xlg {
    @apply text-[20px] w-[20px] h-[20px];
  }

  .btn-icon-sm > img,
  .btn-icon-sm > svg,
  .btn-icon-sm > i {
    @apply text-[20px] w-[20px] h-[20px];
  }

  .btn-icon.btn-sm {
    @apply w-[43px] h-[43px];
  }

  .btn-black {
    @apply relative overflow-hidden z-[1] text-white bg-transparent border-transparent;
  }
  .btn-black::after {
    content: '';
    @apply block absolute bottom-0 left-0 z-[-2] w-full h-full bg-black border border-black;
  }
  .btn-black::before {
    content: '';
    @apply block absolute bottom-0 left-0 z-[-1] w-0 h-full bg-primary transition-all duration-300;
  }
  .btn-black:hover::before,
  .btn-black:focus::before {
    @apply w-full;
  }

  .btn-outline-black {
    @apply relative overflow-hidden z-[1] text-black bg-transparent border-transparent  transition-all duration-300;
  }
  .btn-outline-black:hover,
  .btn-outline-black:focus {
    @apply text-white;
  }
  .btn-outline-black::after {
    content: '';
    @apply block absolute bottom-0 left-0 z-[-2] w-full h-full bg-transparent border border-black;
  }
  .btn-outline-black::before {
    content: '';
    @apply block absolute bottom-0 left-0 z-[-1] w-0 h-full bg-primary border-primary  transition-all duration-300;
  }
  .btn-outline-black:hover::before,
  .btn-outline-black:focus::before {
    @apply w-full;
  }

  .btn-solid-black {
    @apply relative overflow-hidden z-[1] text-white bg-black border-black transition-all duration-300;
  }
  .btn-solid-black:hover,
  .btn-solid-black:focus {
    @apply text-white bg-primary border-primary;
  }

  .btn-solid-black-secondary {
    @apply relative overflow-hidden z-[1] text-white bg-black border-black transition-all duration-300;
  }
  .btn-solid-black-secondary:hover,
  .btn-solid-black-secondary:focus {
    @apply text-white bg-secondary border-secondary;
  }

  .btn-primary {
    @apply relative overflow-hidden z-[1] text-white bg-transparent border-transparent;
  }
  .btn-primary::after {
    content: '';
    @apply block absolute bottom-0 left-0 z-[-2] w-full h-full bg-primary border border-primary;
  }
  .btn-primary::before {
    content: '';
    @apply block absolute bottom-0 left-0 z-[-1] w-0 h-full bg-black  transition-all duration-300;
  }
  .btn-primary:hover::before,
  .btn-primary:focus::before {
    @apply w-full;
  }

  .btn-outline-primary {
    @apply relative overflow-hidden z-[1] text-black bg-transparent border-transparent  transition-all duration-300;
  }
  .btn-outline-primary:hover,
  .btn-outline-primary:focus {
    @apply text-white;
  }
  .btn-outline-primary::after {
    content: '';
    @apply block absolute bottom-0 left-0 z-[-2] w-full h-full bg-transparent border border-primary;
  }
  .btn-outline-primary::before {
    content: '';
    @apply block absolute bottom-0 left-0 z-[-1] w-0 h-full bg-primary border-primary transition-all duration-300;
  }
  .btn-outline-primary:hover::before,
  .btn-outline-primary:focus::before {
    @apply w-full;
  }

  .btn-outline-primary-warning {
    @apply relative overflow-hidden z-[1] text-black bg-transparent border-transparent  transition-all duration-300;
  }
  .btn-outline-primary-warning:hover,
  .btn-outline-primary-warning:focus {
    @apply text-white;
  }
  .btn-outline-primary-warning::after {
    content: '';
    @apply block absolute bottom-0 left-0 z-[-2] w-full h-full bg-transparent border border-primary;
  }
  .btn-outline-primary-warning::before {
    content: '';
    @apply block absolute bottom-0 left-0 z-[-1] w-0 h-full bg-warning border-warning transition-all duration-300;
  }
  .btn-outline-primary-warning:hover::before,
  .btn-outline-primary-warning:focus::before {
    @apply w-full;
  }

  .btn-solid-primary {
    @apply relative overflow-hidden z-[1] text-white bg-primary border-primary transition-all duration-300;
  }
  .btn-solid-primary:hover,
  .btn-solid-primary:focus {
    @apply text-white bg-black border-black;
  }

  .btn-outline-white {
    @apply relative overflow-hidden z-[1] text-white bg-transparent border-transparent  transition-all duration-300;
  }
  .btn-outline-white:hover,
  .btn-outline-white:focus {
    @apply text-white;
  }
  .btn-outline-white::after {
    content: '';
    @apply block absolute bottom-0 left-0 z-[-2] w-full h-full bg-transparent border border-white;
  }
  .btn-outline-white::before {
    content: '';
    @apply block absolute bottom-0 left-0 z-[-1] w-0 h-full bg-primary border-primary  transition-all duration-300;
  }
  .btn-outline-white:hover::before,
  .btn-outline-white:focus::before {
    @apply w-full;
  }

  .btn-danger {
    @apply relative overflow-hidden z-[1] text-white bg-transparent border-transparent;
  }
  .btn-danger::after {
    content: '';
    @apply block absolute bottom-0 left-0 z-[-2] w-full h-full bg-danger border border-danger;
  }
  .btn-danger::before {
    content: '';
    @apply block absolute bottom-0 left-0 z-[-1] w-0 h-full bg-primary transition-all duration-300;
  }
  .btn-danger:hover::before,
  .btn-danger:focus::before {
    @apply w-full;
  }
}

@layer components {
  input[type='text'] {
    @apply outline-none focus:outline-none focus:ring-0;
  }

  .field {
    @apply block w-full py-[13px] px-5 text-base leading-tight font-normal select-none focus:no-underline border border-transparent rounded outline-none focus:outline-none focus:ring-0;
  }

  .field-xl {
    @apply py-[19px] px-7 text-base leading-tight rounded;
  }

  .field-lg {
    @apply py-[16px] px-6 text-base leading-tight rounded-2xs;
  }

  .field-sm {
    @apply py-[12px] px-[18px] text-sm leading-tight rounded-2xs;
  }

  .field-white {
    @apply text-black placeholder-secondary bg-white border border-white focus:border-primary transition-colors duration-300;
  }

  .field-outline-white {
    @apply text-black placeholder-secondary bg-white border border-light-200 hover:border-primary hover:border-opacity-60 focus:border-primary transition-colors duration-300;
  }

  .field-light {
    @apply text-black placeholder-secondary bg-light border border-light focus:border-primary transition-colors duration-300;
  }

  .field-pill {
    @apply rounded-full;
  }
}

@layer components {
  .wallet-connected:not(.wallet-hovered) * {
    @apply fill-warning stroke-transparent;
  }

  .wallet-connected.wallet-hovered * {
    @apply stroke-warning fill-transparent;
  }
}

@layer utilities {
  .h-layout {
    height: calc(100vh - 70px);
  }
  @media screen(md) {
    .h-layout {
      height: calc(100vh - 80px);
    }
  }

  .hide-scrollbar::-webkit-scrollbar {
    @apply hidden w-0 opacity-0;
  }

  .zoom-hover {
    @apply transform scale-100 hover:scale-[1.2] transition-all duration-300 ease-in-out;
  }
}
